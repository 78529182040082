import axios from "@/axios";
import { mutations } from "./store";
import VueCookies from "vue-cookies";

const sendOtpForLogin = async (payload) => {
  try {
    const url = `user-auth/password-login`;
    const { data } = await axios.post(url, payload);
    const { email } = payload;
    let userName = data?.data?.user_name ? data?.data?.user_name : "user";
    userName = userName.replace(/\b\w/g, (match) => match.toUpperCase());
    mutations.setUserName(userName);
    mutations.setUserEmail(email);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const verifyOtp = async (payload) => {
  try {
    const url = `user-auth/verify-otp`;
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const resendOtp = async (payload) => {
  try {
    const url = `global-auth/resend-otp`; 
    const { data } = await axios.post(url, {request_id: payload.requestId, email: payload.email});
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const setTempPass = async (email) => {
  try {
    const emailFromPayload = email.email;
    const url = `user-auth/set-temp-password?email=${email}`;
    const { data } = await axios.post(url);
    let userName = data?.data?.user_name ? data?.data?.user_name : "user";
    userName = userName.replace(/\b\w/g, (match) => match.toUpperCase());
    mutations.setUserName(userName);
    mutations.setUserEmail(emailFromPayload);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};


const setGlobalAuthNewPassword = async (payload) => {
  try {
    const emailFromPayload = payload.email;
    const url = `global-auth/global-set-new-user-password`;
    const { data } = await axios.post(url, payload);
    let userName = data?.data?.user_name ? data?.data?.user_name : "user";
    userName = userName.replace(/\b\w/g, (match) => match.toUpperCase());
    mutations.setUserName(userName);
    mutations.setUserEmail(emailFromPayload);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const requestResetPassword = async (payload) => {
  try {
    const url = `global-auth/request-reset-password`;
    const response = axios.post(url, payload);
    mutations.setUserName(response?.data?.data?.user_name || "user");
    mutations.setUserEmail(payload.email);
    return response
  } catch(err) {
    console.log("err", err);
    return err;
  }

}

const submitResetPassword = async (payload) => {
  try {
    const url = `global-auth/submit-reset-password`;
    const response = axios.post(url, payload);
    mutations.setUserName(response?.data?.data?.user_name || "user");
    mutations.setUserEmail(payload.email);
    return response
  } catch(err) {
    console.log("err", err);
    return err;
  }

}


const verifyTempPass = async (payload) => {
  try {
    const url = `user-auth/verify-temp-password`;
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const verifyGlobalAuthTempPass = async (payload) => {
  try {
    const url = `global-auth/validate-reset-password`;
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const setNewPass = async (payload) => {
  try {
    const url = `user-auth/set-password`;
    const { data } = await axios.post(url, payload);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getLoginInfo = async () => {
  try {
    let token = VueCookies.get("token");
    const url = `/tenant/get-tenant-information`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data) mutations.setLoginScreenInfo(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const verifyLoginEmail = async (payload) => {
  try {
    const url = `global-auth/verify-login-email`;
    const response = await axios.post(url, payload);
    // single ORG with SSO
    // let singleOrgWithSso = {
    //   success: true,
    //   next_ui: "REQUEST_SSO_REDIRECT",
    //   data: {
    //     user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //     tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //     client_id: null,
    //     org_type: "TENANT",
    //     authentication_methods: [
    //       {
    //         id: "31f11473-e253-42bb-8a05-c2b2e38fa9be",
    //         auth_type: "external_idp",
    //         user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //         active: true,
    //         authentication_user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //         idp_meta: null,
    //         org_type: "TENANT",
    //         tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //         tenant_name: "Rope",
    //       },
    //     ],
    //   },
    // };

    // let singleOrgWithPassword = {
    //   success: true,
    //   next_ui: "REQUEST_EMAIL_VERIFICATION_OTP",
    //   data: {
    //     success: true,
    //     request_id: "dd7b49fd-6613-401d-8d0b-2bf1a727977e",
    //     bypass_otp: false,
    //     flow_reason: "Single organisation",
    //   },
    // };

    // let singleOrgWithMultipleAuth = {
    //   request_id: "e8fbecfb-28c3-4efb-b9b9-07a663d19968",
    //   next_ui: "ORGANIZATION_SELECTION",
    //   data: [
    //     {
    //       user_id: "b61292d4-d051-70aa-d48c-f8a613ef7fb0",
    //       tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //       client_id: null,
    //       org_type: "TENANT",
    //       tenant_name: "Rope",
    //       client_name: null,
    //       authentication_methods: [
    //         {
    //           id: "dbecb546-93da-4028-a7cb-a6028ae4a042",
    //           auth_type: "internal_cognito",
    //           user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //           active: true,
    //           authentication_user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //           idp_meta: null,
    //           org_type: "TENANT",
    //           tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           tenant_name: "Rope",
    //           "next-state": "REQUEST_PASSWORD",
    //         },
    //         {
    //           id: "31f11473-e253-42bb-8a05-c2b2e38fa9be",
    //           auth_type: "external_idp",
    //           user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //           active: true,
    //           authentication_user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //           idp_meta: null,
    //           org_type: "TENANT",
    //           tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           tenant_name: "Rope",
    //         },
    //       ],
    //     },
    //   ],
    // };

    // let multiOrgWithMultiAuth = {
    //   success: true,
    //   next_ui: "REQUEST_EMAIL_VERIFICATION_OTP",
    //   data: {
    //     success: true,
    //     request_id: "dd7b49fd-6613-401d-8d0b-2bf1a727977e",
    //     bypass_otp: false,
    //     flow_reason: "Multiple organisations",
    //   },
    //   status_code: 1000,
    //   message: "Success",
    // };
    return response;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

const verifyRegistrationEmail = async (payload) => {
  try {
    const url = `global-auth/verify-registration-email`;
    const response = await axios.post(url, payload);
    return response;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

const requestUserRegistration = async (payload) => {
  try {
    const url = `global-auth/request-registration`;
    const response = await axios.post(url, payload);
    return response;
  } catch (err){
    console.error("err", err);
    return err
  }
}

const selfRegisterViaPassword = async (payload) => {
  try {
    const url = `global-auth/register-via-password`;
    const response = await axios.post(url, payload);
    return response;
  } catch (err){
    console.error("err", err);
    return err
  }
}



const validateRegistrationOTP= async (payload) => {
  try {
    const url = `global-auth/validate-registration-otp`;
    const response = await axios.post(url, payload);
    return response;
  } catch (err){
    console.error("err", err);
    return err
  }
}

const getSsoRedirectUrl = async (payload) => {
  try {
    const url = `global-auth/get-sso-redirect-url`;
    const response = await axios.post(url, payload);
    return response;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

const validateGlobalAuthOtp = async (payload) => {
  try {
    const url = `global-auth/validate-otp`;
    const response = axios.post(url, payload);
    // let singleOrgWithMultipleAuth = {
    //   request_id: "e8fbecfb-28c3-4efb-b9b9-07a663d19968",
    //   data: [
    //     {
    //       user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //       tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //       client_id: null,
    //       org_type: "TENANT",
    //       tenant_name: "Rope",
    //       authentication_methods: [
    //         {
    //           id: "dbecb546-93da-4028-a7cb-a6028ae4a042",
    //           auth_type: "internal_cognito",
    //           user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //           active: true,
    //           authentication_user_id: "0642a264-f0d1-700a-13e2-e53811f388bf",
    //           idp_meta: null,
    //           org_type: "TENANT",
    //           tenant_id: "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           tenant_name: "Rope",
    //           "next-state": "REQUEST_PASSWORD",
    //           auth_name: "",
    //         },
    //       ],
    //     },
    //   ],
    // };

    // let multiOrgWithMultiAuth = {
    //   "request_id": "dd7b49fd-6613-401d-8d0b-2bf1a727977e",
    //   "next_ui": "ORGANIZATION_SELECTION",
    //   "data": [
    //     {
    //       "user_id": "b61292d4-d051-70aa-d48c-f8a613ef7fb0",
    //       "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //       "client_id": null,
    //       "org_type": "TENANT",
    //       "tenant_name": "Rope",
    //       "client_name": null,
    //       "authentication_methods": [
    //         {
    //           "id": "e98f5bf1-6932-404f-a508-d5ddb1388a80",
    //           "auth_type": "internal_cognito",
    //           "user_id": "b61292d4-d051-70aa-d48c-f8a613ef7fb0",
    //           "active": true,
    //           "authentication_user_id": "b61292d4-d051-70aa-d48c-f8a613ef7fb0",
    //           "userpool_authentication_method": null,
    //           "idp_meta": null,
    //           "org_type": "TENANT",
    //           "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           "tenant_name": "Rope",
    //           "next-state": "REQUEST_PASSWORD"
    //         }
    //       ]
    //     },
    //     {
    //       "user_id": "46c22274-6031-70f2-4e82-4899ac29bdd2",
    //       "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //       "client_id": "404150f9-aa0c-4992-b4ac-4f9f484f944f",
    //       "org_type": "CLIENT_INTERNAL",
    //       "tenant_name": "Rope",
    //       "client_name": "Navneet",
    //       "authentication_methods": [
    //         {
    //           "id": "26ae741c-aa59-46e0-ae6d-65b72a923d9f",
    //           "auth_type": "internal_cognito",
    //           "user_id": "46c22274-6031-70f2-4e82-4899ac29bdd2",
    //           "active": true,
    //           "authentication_user_id": "46c22274-6031-70f2-4e82-4899ac29bdd2",
    //           "userpool_authentication_method": null,
    //           "idp_meta": null,
    //           "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           "client_id": "404150f9-aa0c-4992-b4ac-4f9f484f944f",
    //           "tenant_name": "Rope",
    //           "client_name": "Navneet",
    //           "org_type": "CLIENT_INTERNAL",
    //           "next-state": "REQUEST_PASSWORD"
    //         }
    //       ]
    //     },
    //     {
    //       "user_id": "06b22254-90b1-70a5-4951-1cd3cef5b12e",
    //       "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //       "client_id": "cc57d529-ce61-4ae7-810a-e4afc973db68",
    //       "org_type": "CLIENT_INTERNAL",
    //       "tenant_name": "Rope",
    //       "client_name": "prerana",
    //       "authentication_methods": [
    //         {
    //           "id": "76342018-6a4f-4222-b61c-018ea6633aba",
    //           "auth_type": "internal_cognito",
    //           "user_id": "06b22254-90b1-70a5-4951-1cd3cef5b12e",
    //           "active": true,
    //           "authentication_user_id": "06b22254-90b1-70a5-4951-1cd3cef5b12e",
    //           "userpool_authentication_method": null,
    //           "idp_meta": null,
    //           "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           "client_id": "cc57d529-ce61-4ae7-810a-e4afc973db68",
    //           "tenant_name": "Rope",
    //           "client_name": "prerana",
    //           "org_type": "CLIENT_INTERNAL",
    //           "next-state": "REQUEST_PASSWORD"
    //         },
    //         {
    //           "id": "c1afd5e2-135c-430b-ae71-17e27b4c2062",
    //           "auth_type": "external_idp",
    //           "user_id": "06b22254-90b1-70a5-4951-1cd3cef5b12e",
    //           "active": true,
    //           "authentication_user_id": "b60212c4-b0a1-7013-c11f-51294d195181",
    //           "userpool_authentication_method": null,
    //           "idp_meta": null,
    //           "tenant_id": "53cf2a5e-aea4-44cd-a1a1-8120d3836601",
    //           "client_id": "cc57d529-ce61-4ae7-810a-e4afc973db68",
    //           "tenant_name": "Rope",
    //           "client_name": "prerana",
    //           "org_type": "CLIENT_INTERNAL",
    //           "next-state": "REQUEST_SSO_REDIRECT"
    //         }
    //       ]
    //     }
    //   ]
    // }
    return response;
  } catch (err) {
    console.log("Err", err);
    return err;
  }
};

const submitPasswordForLogin = async (payload) => {
  try {
    const url = `/global-auth/submit-password`;
    const response = await axios.post(url, payload);
    return response;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

const sendOtp = async (payload) => {
  try {
    const url = `/global-auth/send-otp`;
    const { data } = await axios.post(url, payload);
    return data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

const resendRegOtp = async (payload) => {
  try {
    const url = `/global-auth/resend-registration-otp`;
    const {data} = await axios.post(url, payload);
    return data;
  } catch (err) {
    console.log("err", err);
    return err;
  }
};

export {
  sendOtpForLogin,
  verifyOtp,
  resendOtp,
  setTempPass,
  verifyTempPass,
  setNewPass,
  getLoginInfo,
  verifyLoginEmail,
  getSsoRedirectUrl,
  validateGlobalAuthOtp,
  submitPasswordForLogin,
  setGlobalAuthNewPassword,
  requestResetPassword,
  verifyGlobalAuthTempPass,
  submitResetPassword,
  verifyRegistrationEmail,
  requestUserRegistration,
  validateRegistrationOTP,
  selfRegisterViaPassword,
  sendOtp,
  resendRegOtp,
};
