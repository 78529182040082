<template>
    <div class="w-full bg-white flex justify-center items-center">
        {{ $t('general.loading_dots') }}
    </div>
</template>
<script>
import { getQueryDetails } from '../../../../shared/light-ray/services';
import { getCaseDetail} from '../../../../shared/light-ray/services';
import { getSaasTokenRedirect}  from  '../../../../shared/light-ray/services'
import VueCookies  from 'vue-cookies';
export default {
    name: "redirection",
    data: () => {
        return {
            loading: false,
        }
    },
    async mounted() {
        let data = await getCaseDetail(this.$route.params.case_id);
        let datatoken = await getSaasTokenRedirect({ client_id: data?.data?.client_id }, VueCookies.get("token"));
        VueCookies.set("saas-token", datatoken.data.token);
        let query = await getQueryDetails(this.$route.params.case_id);
        query = query?.data?.data[0];
        data = data?.data;
        sessionStorage.setItem('id', data.id);
        sessionStorage.setItem('neo_case_id', data.neo_case_id);
        localStorage.setItem("package_name", data.package_name);
        await this.$store.dispatch("getEntitiesList");
        const entityList = this.$store.getters.getEntitiesList;
        if (entityList && entityList.length) {
            let runIntelligence = false;
            for (const entity of entityList) {
                if (entity["run_intelligence"]) {
                    runIntelligence = true;
                    break;
                }
            }
            if (runIntelligence) {
                this.$router.push({
                    name: "entity-details",
                    params: { tool: "Entity-Details" },
                    query: {
                        case_id: this.$route.params.case_id,
                        code: query?.query?.jurisdiction_code || "",
                        // doc_id: this.selectedCompanyData.doc_id,
                        // company: this.selectedCompanyData.company.company_number,
                        // query_id: query?._id,
                        name: data.case_name,
                    },
                });
            } else {
                this.$router.push({
                    name: "add-multientities",
                    query: {
                        package_id: data?.package_id,
                        client_id: data?.client_id,
                        t: new Date().getTime(),
                    },
                });
            }
        } else {
            this.$router.push({
                name: "add-multientities",
                query: {
                    package_id: data?.package_id,
                    client_id: data?.client_id,
                    t: new Date().getTime(),
                },
            });
        }
        /**
         * TO DO Remove commented code once backend fix
         *
         */
        // if (!data.created) {
        //     // 
        //     this.$router.push(
        //     { name:"add-multientities", 
        //       query: {package_id: data?.package_id, client_id:  data?.client_id,t: new Date().getTime()}
        //    })
        // } else {
        //     this.$router.push({
        //         name: "entity-details",
        //         params: { tool: "Entity-Details" },
        //         query: {
        //             case_id: this.$route.params.case_id,
        //             code: query?.query?.jurisdiction_code || '',
        //             // doc_id: this.selectedCompanyData.doc_id,
        //             // company: this.selectedCompanyData.company.company_number,
        //             // query_id: query?._id,
        //             name: data.case_name
        //         }
        //     })
        // }
    },
    methods: {


    }
}
</script>