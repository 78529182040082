<template>
  <div>
    <div class="custom-container">
      <section class="brand">
        <div id="bg">
          <img :src="bgImg" class="loginImg" />
        </div>
      </section>
      <section class="auth">
        <template>
          <div class="w-full form-cnt min-h-full">
            <form @submit.prevent="submitForm" class="pr-10">
              <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                <div v-if="loginInfo" :style="{ paddingBottom: '6px' }">
                  <img
                    v-if="loginScreenInfo.logo_url"
                    :src="loginScreenInfo.logo_url"
                    class="custom_logo"
                  />
                  <div class="tagline_cnt">
                    <span class="tagline">{{ loginScreenInfo.tagline }}</span>
                  </div>
                </div>
                <div>
                  <p class="block mb-2 text-black text-2xl font-semibold">
                    {{ $t("login.login_label") }}
                  </p>
                </div>
                <div class="flex flex-col gap-4">
                  <div class="relative">
                    <label
                      for="Email"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >{{ $t("fields.email") }}</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="text"
                        id="Email"
                        v-model="formData.email"
                        class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12"
                        :class="
                          showError
                            ? 'border-red-600'
                            : 'border-gray-300'
                        "
                        :placeholder="$t('fields.placeholders.email')"
                        :disabled="isEmailFilled"
                        @change="disableError"
                      />
                    </div>
                    <span
                      :class="{ hideError: !(showError && !isEmailValid) }"
                      class="text-red-600 text-xs error"
                      >{{ $t("fields.errors.email") }}</span
                    >
                    <span
                      v-if="showError && emailNotRegister && isEmailValid"
                      class="text-red-600 text-xs error"
                      >Your email ID is not registered</span
                    >
                  </div>
                </div>
                <div class="flex flex-col gap-4">
                  <button
                    class="form-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer login-button"
                    :class="{ btnDisable: !isFormFilled || disabled }"
                    type="submit"
                    :disabled="!isFormFilled || disabled"
                  >
                    <Loader v-if="disabled" class="h-8 w-8" :size="8"></Loader>
                    {{ !disabled ? "Login" : "" }}
                  </button>
                  <div class="flex justify-start items-center">
                    Don't have an account?
                    <span
                      class="px-1 text-brand cursor-pointer"
                      @click="handleSelfRegistration"
                      >Register</span
                    >
                  </div>
                  <div
                    class="flex justify-center items-center powered_by_cnt"
                    v-if="loginScreenInfo.powered_by && loginInfo"
                  >
                    <span class="poweredBy">Powered by</span>
                    <img :src="NeotasLogo" class="poweredBy_logo" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
import { LoginImg, NeotasLogo, bgImg } from "@/plugins/common.js";
import { verifyLoginEmail, getLoginInfo } from "./services";
import { mapActions, mapGetters } from "vuex"
import Loader from "@shared/loader";
import sections from "./store";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      LoginImg,
      NeotasLogo,
      bgImg,
      // defaultLogo,
      disabled: false,
      requestId: null,
      formData: {
        email: "",
      },
      showError: false,
      isEmailFilled: false,
      loginInfo: false,
      emailNotRegister: false,
    };
  },
  watch: {
    formData: {
      handler(newVal) {
          if(newVal.email === "") {
            this.showError = false;
            this.emailNotRegister = false;
          }
      },
      deep: true
    }
  },
  async mounted() {
    if (this.$route?.params?.email) {
      this.formData.email = this.$route?.params?.email;
      this.isEmailFilled = true;
    }
    await getLoginInfo();
    this.loginInfo = true;
  },
  computed: {
    ...mapGetters(["getUnifiedPortalState"]),
    isFormFilled() {
      return this.formData?.email;
    },
    isEmailValid() {
      return this.formData.email ? this.isValidEmail(this.formData.email) : true;
    },
    loginScreenInfo() {
      return sections.login_screen_info;
    },
    poweredByImgUrl() {
      // return sections.login_screen_info.logo_url
      return `data:image/png;base64,${this.loginScreenInfo.logo}`;
    },
  },
  methods: {
    ...mapActions(['manageGlobalAuthData', 'setUnifiedPortalState', 'ssoRedirectLogin']),
    handleSelfRegistration() {
      this.$router.push({
        name: "SelfRegistration",
        query: { email: this.formData.email },
      });
    },
    async submitForm() {
      try {
        this.disabled = true;
        if (!this.isEmailValid) {
          this.showError = true;
          this.disabled = false
          return
        }
        const { data } = await verifyLoginEmail(this.formData);
        // set data for unified portal
        if(data?.success) {
          this.setUnifiedPortalState({email: this.formData.email, request_id: data.data.request_id, next_ui: data.next_ui});
          this.handleNextUiForLogin(data?.next_ui, data);
        }
        // TO DO -  handle id success is false
        this.disabled = false;
      } catch (error) {
        this.disabled = false;
        this.$toast.error(error?.response?.data || "Something went wrong!");
        console.error("error", error);
      }
    },

    handleNextUiForLogin(nextUi, data) {
      switch (nextUi) {
        case "REQUEST_SSO_REDIRECT": {
          this.handleSSOLogin(data);
          break;
        }
        case "REQUEST_EMAIL_VERIFICATION_OTP": {
          this.handleOtpLogin(data);
          break;
        }
        case "ORGANIZATION_SELECTION": {
          this.handleOrganizationsSelectionLogin(data);
          break;
        }
        case "AUTHENTICATION_METHOD_SELECTION": {
          this.handleOrganizationsSelectionLogin(data);
          break;
        }
        case "LOGIN_FAILED": {
          if(data?.data.message) {
            this.$toast.error(data?.data.message || "Something went wrong");
          }
          this.showError = true;
          this.emailNotRegister = true;
          break;
        }
      }
    },
    handleSSOLogin(data) {
      try {
        const { authentication_methods } = data?.data;
        let authMethod = Array.isArray(authentication_methods) ? authentication_methods[0] : authentication_methods;
        let payload = {
          org_id: this.getOrganizationId(data.data),
          org_type: authMethod?.org_type,
          email: this.formData.email,
          authentication_user_id: authMethod?.authentication_user_id,
          authentication_method_id: authMethod?.userpool_authentication_method,
          request_id: data?.data?.request_id,
        };
        this.ssoRedirectLogin({ payload, userType: 'existing'})
        // this.$store.dispatch("ssoRedirectLogin", { payload, userType: 'existing'});
      } catch (err) {
        console.error("err", err);
        this.$toast.error(err?.response?.data?.detail || "something went wrong");
      }
    },
    handleOrganizationsSelectionLogin(data) {
      // this.$store.dispatch("manageGlobalAuthData", data);
      this.manageGlobalAuthData(data);
      this.$router.push({
        name: "UnifiedPortalLogin",
        query: {
          email: this.formData.email,
          requestId: data?.data?.request_id,
        }
      });
    },
    getOrganizationId(data) {
      return data?.authentication_methods[0]?.org_type === "TENANT"
        ? data?.tenant_id
        : data?.client_id;
    },
    handleOtpLogin(data) {
      this.$router.push({
        name: "otp-login",
        query: {
          requestId: data?.data?.request_id,
          email: this.formData.email,
        },
      });
    },
    disableError() {
      this.disabled = false;
    },
    isValidEmail(email) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    },
  },
  
};
</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }

  // .loginImage-cnt {
  //     width: 50%;
  //     height: 100vh;
  //     // overflow: hidden;
  //     display: flex;
  // }
  .form-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6%;
    background-color: white;
  }
  .logo {
    width: 200px;
    height: 72px;
  }
  .btnDisable {
    background-color: #ddd;
    color: #858585;
  }
  // .rounded{
  //     border-radius: 10px;
  // }
  .form-cnt-div {
    padding: 3rem;
  }
  input {
    font-size: 16px;
    border-radius: 10px;
    // border: 1px solid  #d8dbe0;
  }
  input:focus {
    outline: 1px solid rgb(59, 130, 246);
  }
  input::placeholder {
    // color: #49454F;
  }
  .login-button {
    border-radius: 10px;
    border-width: 0px;
    font-size: 16px;
    // background-color: #0d69d5;
    font-weight: 700;
  }
  .forget-pass {
    color: rgb(13, 106, 213);
  }
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .auth {
    overflow-y: scroll;
  }
  form {
    max-width: 36rem;
  }
  .hideError {
    visibility: hidden;
  }
  .error {
    position: absolute;
  }
  .loginImg {
    width: 100%;
    height: 100%;
  }
  .tagline {
    font-size: 16px;
    font-weight: 600;
  }
  .poweredBy {
    font-size: 12px;
  }
  .poweredBy_logo {
    max-height: 28px;
    min-height: 20px;
    margin-left: 10px;
  }
  .powered_by_cnt {
    margin-top: 15px;
  }
  .tagline_cnt {
    margin-top: 19px;
  }
  .custom_logo {
    max-height: 130px;
    min-height: 72px;
  }
}
</style>
